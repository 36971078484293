import React from "react";

// Components
import SEO from "../components/seo";
import Layout from "../components/layout";

// SCSS
import { gutter } from "../style/style.module.scss";

// ReCaptcha
import { RunReCaptcha } from "../services/recaptcha";

const AboutPage = (): JSX.Element => {
  // Render
  return (
    <Layout>
      <SEO title="Company Overview: About Us" />
      <RunReCaptcha action="overview" />
      <div className={gutter}>
        <h1>About Us</h1>
        <h2>Company Overview</h2>
        <p>
          NanoPharmaceuticals LLC is an emerging, clinical stage pharmaceutical
          company devoted to the discovery and commercialization of new,
          molecularly-targeted drugs for diseases with major unmet needs.
        </p>
        <p>
          Over the past decade, the company’s researchers have defined the
          previously unrecognized anticancer attributes of tetraiodothyroacetic
          acid (tetrac), a naturally occurring derivative of L-thyroxine (T4).
          Tetrac was thought to have little biological activity at the classic
          nuclear receptors for biologically active triiodothyronine (T3).
          NanoPharmaceuticals’ scientists discovered that tetrac has anticancer
          activity on the cell membrane at a receptor they designated
          thyrointegrin αvβ3. While this receptor exists on all cells, it is
          over-expressed in the active form on cancer cells and rapidly dividing
          endothelial cells, but is quiescent on nonmalignant, non-dividing
          cells. From this vantage point on tumor cells, tetrac and
          chemically-modified tetrac regulate multiple signal transduction
          systems that, inside the cell, regulate the cell cycle (cell
          division), cancer cell defense mechanisms against chemotherapy, and
          tumor-relevant blood vessel formation (angiogenesis). The signaling
          systems that tetrac-based agents regulate act to control the
          expression of large numbers of cancer cell genes, but spare normal
          cells.
        </p>
        <p>
          Because of these actions, our lead compound has proven to be highly
          effective in preclinical models against all human cancers studied.
          These include glioblastoma (GBM), acute myeloid leukemia (AML),
          neuroblastoma, and cancers of the breast, prostate, pancreas, kidney,
          lung and bladder. Melanoma and thyroid cancers are also susceptible to
          tetrac action. Tumors established in animal models from human cell
          lines and from primary cancer cell culture are equally responsive to
          the tetrac agents. Results included 90% reduction in tumor size
          because of programmed cell death (apoptosis) and disappearance of
          blood vessels without hemorrhage. Adverse effects were not encountered
          in treated animal models. This was the case even when animals were
          tested at doses 100-times the proposed dose for first-in-human use.
          The lack of side effects reflects the fact that our active
          pharmaceutical ingredient (API) is not cytotoxic, occurs naturally in
          human subjects, does not enter the nucleus of the cell, and reacts
          only with the active αvβ3 integrins on the cancer cells and rapidly
          dividing blood vessel cells. This lead compound is now being tested in
          a Phase I clinical trial for patients with glioblastoma. Management is
          confident that this trial will demonstrate the safety of the agent and
          its potential in managing a very difficult-to-treat cancer.
        </p>
        <p>
          Based on preclinical studies, the Company’s other drug candidates also
          have the capacity to disrupt abnormal blood vessel production in
          certain non-cancer disease states (Pathological
          Angiogenesis-Associated Disorders). These states include skin
          disorders and eye diseases.
        </p>
      </div>
    </Layout>
  );
};

export default AboutPage;
